import { buildMessageContext } from '@/reporting/buildMessageContext';
import { captureMessage } from '@/reporting/captureMessage';
import { LinkStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';

/**
 * Resolves a web link from a storyblok link object
 * @param link - The link object from storyblok
 * @param context - An optional context to help debug the link
 */
export function resolveWebLink(
  link: NonNullable<LinkStoryblok['link']>,
  context: { story: StoryblokStory<StoryblokComponent>; component?: StoryblokComponent; additional?: Record<string, any> },
) {
  let url = typeof link.url === 'string' && !!link.url ? link.url : link.cached_url;
  if (!url) {
    captureMessage(`Unable to resolve link`, { contexts: { ...(context && buildMessageContext(context)), ...context?.additional } });
    return '#';
  }
  if (['http', 'mailto:', 'tel:', '#'].some(part => url!.startsWith(part))) {
    return url;
  }

  if (!url.startsWith('/')) {
    url = `/${url}`;
  }

  if (new RegExp('/prenota-visita/(.+)_(.+)').test(url)) {
    const slug = url.replace('/prenota-visita/', '');
    slug.replace(/_city$/, '');
    const parts = slug.split('_').filter(v => v.length > 0);

    if (parts.length === 1) {
      url = `/prenota-visita/${parts[0]}`;
    } else {
      url = `/prenota-visita/${parts[0]}/${parts[1]}`;
    }
  }

  const isMedicoUrl = url.startsWith('/medico');
  const isMedicoStory = context.story.full_slug.startsWith('medico');

  if (isMedicoUrl && isMedicoStory) {
    url = url.replace('/medico', '');
  } else if (isMedicoUrl && !isMedicoStory) {
    url = `${process.env.NEXT_PUBLIC_ELTY_MMG_PUBLIC_URL}${url.replace('/medico', '')}`;
  } else if (!isMedicoUrl && isMedicoStory) {
    url = `${process.env.NEXT_PUBLIC_ELTY_PUBLIC_URL}${url}`;
  }
  return url;
}

export function isExternalLink(link: NonNullable<LinkStoryblok['link']>) {
  return link.url && link.url.startsWith('http');
}
