'use client';

import Script from 'next/script';
import { useState } from 'react';
import { ELLoader } from '@davincihealthcare/elty-design-system-react';

const Zendesk = () => {
  const [isShowingCustomButton, setShowCustomButton] = useState(true);
  const [isShowingZenDesk, setShowZendesk] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleZendeskOnLoad = () => {
    window?.zE('webWidget', 'open');
    window?.zE('webWidget:on', 'close', () => {
      setShowCustomButton(false);
      setLoading(false);
    });
  };
  const onClick = () => {
    setLoading(true);
    setShowZendesk(true);
  };
  return (
    <>
      {isShowingZenDesk && (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=03ef222e-14f9-4bfc-ae54-a3434b380ac6"
          onLoad={handleZendeskOnLoad}
        ></Script>
      )}

      {isShowingCustomButton && (
        <button
          aria-label="Help"
          className="transition-2 hover:border-3 fixed bottom-3 right-5 z-50 block h-[50px] w-[50px] rounded-full border-2 bg-elty-green text-white hover:border-gray-300 lg:w-[110px]"
          onClick={onClick}
        >
          {isLoading ? (
            <div className="flex h-[45px] w-[45px] items-center justify-center px-6 lg:w-[110px]">
              <ELLoader />
            </div>
          ) : (
            <div className="flex items-center justify-center space-x-2 px-6 py-3 ">
              <div>
                <svg className="fill-white" width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
                  <g id="Layer_4">
                    <path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"></path>
                    <circle cx="10" cy="15" r="1"></circle>
                    <path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"></path>
                  </g>
                </svg>
              </div>

              <div className="hidden font-mono text-base font-bold lg:block">Help</div>
            </div>
          )}
        </button>
      )}
    </>
  );
};

export default Zendesk;
